import { graphql,Link } from 'gatsby'
import React from 'react'
import Layout from 'components/layout'

const NotFoundPage = ({ location }) => {
    return (
        <Layout location={location}>
            <div className="wrapper inner-page notFoundWrapper vh-100 d-flex flex-column justify-content-center align-item-center">
                <div className="container">
                    <div className="row">
                        <div className="col-sm-12">
                            <div className="not-found  d-flex flex-column justify-content-center align-item-center">
                                <div className="notFound-mainHeading d-flex flex-row justify-content-center align-items-baseline">
                                    <h1 className="m-0">404</h1>
                                    <span>error</span>
                                </div>
                                <h2 className="justify-content-center align-items-center text-center mt-3 mb-3 text-capitalize">Page not found</h2>
                                <div className="text-center text-notfound">
                                    <p className="">We are sorry, the page you requested cannot be found.<br />Please go back to the homepage or <Link to="/contact">Contact Us</Link></p>
                                    <Link to="/"><button className="rounded btn-yellow btn text-white ">Go back</button></Link>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </Layout>
    )
}

export default NotFoundPage
